@banner0: banner0;
.@{banner0} {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://images.pexels.com/photos/587741/pexels-photo-587741.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  & &-text-wrapper {
    display: inline-block;
    position: absolute;
    top: 20%;
    margin: auto;
    left: 0;
    right: 0;
    font-size: 14px;
    color: @template-text-color-light;
    width: 550px;
    >.queue-anim-leaving {
      position: relative !important;
    }
  }
  & &-title {
    margin: auto;
    font-size: 40px;
    position: relative;
  }
  & &-title svg{
    width: 30vw;
    height: auto;
  }
  & &-content {
    margin-bottom: 20px;
    word-wrap: break-word;
    min-height: 24px;
  }
  & &-button {
    border: 2px solid #4D4D4D;
    color: #4D4D4D;
    background: transparent;
    box-shadow: 0 0 0 transparent;
    margin-top: 1rem;
    font-size: 16px;
    height: 40px;
    transition: background .45s @ease-out, box-shadow .45s @ease-out;
    &:hover {
      color: #FBB03B;
      border-color: #FBB03B;
      background-color: #4D4D4D !important;
      box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
    }
    &:focus {
      border-color: #4D4D4D;
    }
    &.queue-anim-leaving {
      width: auto;
    }
  }
  & &-icon {
    bottom: 20px;
    font-size: 24px;
    position: absolute;
    left: 50%;
    margin-left: -12px;
    color: #4D4D4D;
  }
}

@media screen and (max-width: 767px) {
  .@{banner0} {
    background-attachment: inherit;
    & &-text-wrapper {
      width: 90%;
    }
    & &-title {
      width: 90%;
      left: 0;
    }
    & &-title svg{
      width: 60vw;
      height: auto;
    }
  }
}
